import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'
import WelcomeImage from '../images/SectionHeadingIllustrations/Welcome.png'

export default function MainHeading(): ReactElement {
  return (
    <Flex
      id="welcome-to-windscribe"
      sx={{
        pt: ['12rem', '12rem', '8.8rem'],
        pb: '3.2rem',
        flexDirection: ['column', 'column', 'row'],
        alignItems: 'center',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          maxWidth: '98rem',
          mx: ['2.4rem', 0],
          color: 'rgba(255, 255, 255, 0.8)',
          fontWeight: 'bold',
          fontSize: '4.8rem',
          backgroundImage: 'linear-gradient(to right, #58ff8d -5%, #5dffe2 105%)',
          backgroundClip: 'text',
          letterSpacing: '0.4rem',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          textTransform: 'uppercase',
          lineHeight: '48px',
          alignItems: ['center', 'flex-start'],
          textAlign: ['center', 'center', 'left'],
          whiteSpace: 'break-spaces',
        }}
      >
        WELCOME TO WINDSCRIBE
      </Flex>
      <img src={WelcomeImage} alt="Welcome to Windscribe" width={340} height={255} />
    </Flex>
  )
}
