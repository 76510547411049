import React, { ReactElement, ReactNode } from 'react'
import { Box, Text } from 'theme-ui'

// Usage:
// Pass in first letter to firstLetter, and the rest of the paragraph (NOT the first letter) as children
export default function ParagraphWithBigFirstLetter({
  children,
  firstLetter,
}: {
  children: ReactNode
  firstLetter: string
}): ReactElement {
  return (
    <Box sx={{ marginBlockStart: '1em' }}>
      <Text
        sx={{
          fontSize: '6rem',
          fontWeight: 'medium',
          backgroundImage: 'linear-gradient(to right, #58ff8d -5%, #5dffe2 105%)',
          backgroundClip: 'text',
          lineHeight: '60px',
          letterSpacing: '0.6rem',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          float: 'left',
        }}
      >
        {firstLetter}
      </Text>
      <Text>{children}</Text>
    </Box>
  )
}
