const desktopTabletMinHeight = '36em'
const tabletMinWidth = '48em'
const desktopMinWidth = '70em'

const breakpoints = [
  `@media screen and (min-width: ${tabletMinWidth}) and (min-height: ${desktopTabletMinHeight})`,
  `@media screen and (min-width: ${desktopMinWidth}) and (min-height: ${desktopTabletMinHeight})`,
]

export default {
  breakpoints,
}
