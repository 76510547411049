import MainHeading from 'components/MainHeading';
import ParagraphWithBigFirstLetter from 'components/ParagraphWithBigFirstLetter';
import { Flex } from 'theme-ui';
import { StaticImage } from 'gatsby-plugin-image';
import AboutUs from "../../../../src/images/SectionHeadingIllustrations/AboutUs.png";
import ProductsAndServices from "../../../../src/images/SectionHeadingIllustrations/ProductsServices.png";
import TheHumans from "../../../../src/images/SectionHeadingIllustrations/TheHumans.png";
import ToolsOfTheTrade from "../../../../src/images/SectionHeadingIllustrations/ToolsOfTheTrade.png";
import BeforeYouStart from "../../../../src/images/SectionHeadingIllustrations/BeforeYouStart.png";
import YourFirstDays from "../../../../src/images/SectionHeadingIllustrations/YourFirstDays.png";
import YourEveryDay from "../../../../src/images/SectionHeadingIllustrations/YourEveryDay.png";
import Perks from "../../../../src/images/SectionHeadingIllustrations/Perks.png";
import TimeOff from "../../../../src/images/SectionHeadingIllustrations/TimeOff.png";
import RolesAndResponsibilities from "../../../../src/images/SectionHeadingIllustrations/RolesAndResponsibilities.png";
import HowToSucceed from "../../../../src/images/SectionHeadingIllustrations/HowToSucceed.png";
import AppendixA from "../../../../src/images/SectionHeadingIllustrations/AppendixA.png";
import * as React from 'react';
export default {
  MainHeading,
  ParagraphWithBigFirstLetter,
  Flex,
  StaticImage,
  AboutUs,
  ProductsAndServices,
  TheHumans,
  ToolsOfTheTrade,
  BeforeYouStart,
  YourFirstDays,
  YourEveryDay,
  Perks,
  TimeOff,
  RolesAndResponsibilities,
  HowToSucceed,
  AppendixA,
  React
};